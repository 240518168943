import * as React from 'react'

import { Box, Typography } from '@mui/material'

const style = {
    header: { fontWeight: '600', textAlign: 'center', },
    box: { display: 'flex', flexDirection: 'column', alignItems: 'center', border: '2px solid #2F2F2F', padding: '10px', margin: '5px', borderRadius: '10px',
           width: '250px', height: '150px', },
    title: { textAlign: 'center', fontWeight: '600', padding: '15px', },
    time: { color: '#2F2F2F', fontWeight: '600', marginTop: '4%', },
}

const sx = {
    award: { background: '#2F2F2F', borderRadius: '10px', color: '#FFFFFF', ':hover': { color: '#FFFB46', }, }
}

export default function Awards() {
    return (
        <React.Fragment>
            <Box id="Awards" sx={{
                 background: '#FFFFFF', scrollMarginTop: "7.4vh", boxShadow: 'inset 0px 1px 4px 0px rgba(0, 0, 0, 0.1)',
                borderBottom: '1px solid #DDDDDD',
            }}>
                <Box style={{ marginLeft: '27%', marginRight: '27%', }}>
                    <Typography style={ style.header } color='#F06000' fontSize='22.5px' paddingTop='3%'> Awards </Typography>
                    <Typography style={ style.header } color='#000000' fontSize='32.5px'> Honors & Recognition. </Typography>
                    <Typography style={ style.header } color='#2F2F2F' fontSize='17.5px' paddingBottom='4%'>
                        Here are some of the honors and awards I have achieved.
                    </Typography>
                    <Box style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'top', alignItems: 'center', paddingBottom: '4%' }}>
                        {/* <Box style={ style.box }>
                            <Box sx={ sx.award }>
                                <Typography style={ style.title }> Yale Summer Experience Award </Typography>
                            </Box>
                            <Typography style={ style.time }> May 2023 </Typography>
                        </Box> */}
                        <Box style={ style.box }>
                            <Box sx={ sx.award }>
                                <Typography style={ style.title }> Massachusetts Insitute of Technology Book Award </Typography>
                            </Box>
                            <Typography style={ style.time }> Sept. 2020 </Typography>
                        </Box>
                        <Box style={ style.box }>
                            <Box sx={ sx.award }>
                                <Typography style={ style.title }> 2021 National Merit ® Commended Scholar </Typography>
                            </Box>
                            <Typography style={ style.time }> Sept. 2020 </Typography>
                        </Box>
                        <Box style={ style.box }>
                            <Box sx={ sx.award }>
                                <Typography style={ style.title }> National Examination LOTE (Perfect Score) in Polish </Typography>
                            </Box>
                            <Typography margin='4% 0 0 4%' fontStyle='italic' fontSize='10px'>
                                Proficiency in Comprehensive Languages Other Than English Examination
                            </Typography>
                            <Typography style={ style.time }> Nov. 2018 </Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </React.Fragment>
    );
}
