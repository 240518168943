import * as React from 'react'

import { Box, Typography, Link, SvgIcon } from '@mui/material'
import { keyframes } from '@mui/system'

import { ReactComponent as Doodles } from './doodles.svg'
import Title from './title.js'

const arrow = keyframes `
    0% { opacity: 0; } 30% { opacity: 1; }
    60% { opacity: 1; } 100% { opacity: 0; top: 70%; }
`

// const graphic = keyframes ` 0% {  } 30% {  } 60% {  } 100% {  } `

export default function Home() {
    return (
        <React.Fragment>
            <Box id='Home' sx={{
                height: '70vh', display: 'flex', flexDirection:'column', justifyContent: 'center', alignItems: 'center', zIndex: 3, scrollMarginTop: '7.5vh',
                borderBottom: '1px solid #DDDDDD',
            }}>
                <SvgIcon component={Doodles} viewBox='0 0 1700 1200' sx={{
                    width: '52%', height: '70vh', position: 'absolute', left: 0, stroke: '#2F2F2F', strokeWidth: '35px',
                }}/>
                <Box style={{
                    width: '50%', height: '70vh', position: 'absolute', right: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column',
                }}>
                    <Box style={{ width: '50px', height: '50px', borderRadius: '50%', background: '#181414',
                                  position: 'absolute', top: '7%', left: '33%', boxShadow: '0 0 5px 1px black', zIndex: 1}} />
                    <Box style={{ width: '50px', height: '50px', borderRadius: '50%', background: '#181414',
                                  position: 'absolute', top: '7%', right: '33%', boxShadow: '0 0 5px 1px black', zIndex: 1}} />
                    <Box style={{ width: '200px', height: '30px', borderRadius: '50%', background: '#181414', transform: 'rotate(45deg)',
                                  position: 'absolute', top: '18%', left: '32%', boxShadow: '0 0 5px 1px black', }} />
                    <Box style={{ width: '200px', height: '30px', borderRadius: '50%', background: '#181414', transform: 'rotate(-45deg)',
                                  position: 'absolute', top: '18%', right: '32%', boxShadow: '0 0 5px 1px black', }} />
                    <Box style={{ width: '200px', height: '30px', borderRadius: '50%', background: '#181414', transform: 'rotate(-25deg)',
                                  position: 'absolute', bottom: '17%', left: '30%', boxShadow: '0 0 5px 1px black', }} />
                    <Box style={{ width: '200px', height: '30px', borderRadius: '50%', background: '#181414', transform: 'rotate(25deg)',
                                  position: 'absolute', bottom: '17%', right: '30%', boxShadow: '0 0 5px 1px black', }} />
                    <Box style={{ width: '60%', height: '60%', background: '#181414', display: 'flex', justifyContent: 'center',
                                  alignItems: 'center', zIndex: 1, borderRadius: '5%', boxShadow: '0 0 5px 1px black', position: 'relative' }}>
                        <Box sx={{
                            width: '82%', height: '87%', background: '#00a600', position: 'relative', borderRadius: '30% / 5%', zIndex: 1,
                            borderTop: '1px solid rgba(255, 255, 255, 0.55)', borderBottom: '1px solid rgba(255, 255, 255, 0.55)',
                        }}>
                            <Box component='img' src='scanlines.png' sx={{ width: '100%', height: '100%', borderRadius: 'inherit', }} />
                            <Box style={{
                                color: 'white', fontSize: '20px', fontWeight: '600', fontFamily: 'monospace', padding: '25px 17px 25px 17px',
                                position: 'absolute', top: 0, zIndex: 10, textShadow: '0 0 3px white',
                            }}>
                                <Box style={{ marginBottom: '2%', letterSpacing: '2px', }}>&gt; software engineer</Box>
                                <Box style={{ marginBottom: '2%', letterSpacing: '2px', filter: 'blur(0.25px)', }}>&gt; quantitative analyst</Box>
                                <Box style={{ marginBottom: '2%', letterSpacing: '2px', filter: 'blur(0.5px)', }}>&gt; researcher</Box>
                                <Title />
                            </Box>
                            <Box component='img' src='glass1.png' style={{ width: '57%', position: 'absolute', top: '23%', left: '-4%', 
                                 filter: 'invert(1)', }} />
                            <Box component='img' src='glass2.png' style={{ width: '75%', position: 'absolute', top: '23%', left: '-12%', 
                                 filter: 'invert(1)', transform: 'rotate(-45deg)', }} />
                            <Box style={{ width: '200px', height: '200px', position: 'absolute', top: '25%', left: '5%',
                                          backgroundImage: 'radial-gradient( #FFFFFF, transparent 35% )', borderRadius: '50%', }} />
                            <Box style={{ width: '150px', height: '150px', position: 'absolute', top: '35%', left: '-5%',
                                          backgroundImage: 'radial-gradient( #FFFFFF, transparent 60% )', borderRadius: '50%', }} />
                        </Box>
                        <Box sx={{
                            width: '90%', height: '80%', background: '#00a600', position: 'absolute', borderRadius: '5% / 50%',
                        }}>
                            <Box component='img' src='scanlines.png' sx={{ width: '100%', height: '100%', borderRadius: 'inherit', }} />
                        </Box>
                    </Box>
                </Box>
                <Box style={{
                    zIndex: 6, fontSize: '105px', fontWeight: '600', letterSpacing: '0.1px',
                    color: '#2F2F2F', fontFamily: 'Comic Sans MS', textShadow: '0 0 2px black', display: 'flex',
                }}>
                    <Typography style={{ fontSize: '105px', fontWeight: '600', letterSpacing: '0.1px', color: '#2F2F2F',
                                         zIndex: 1, fontFamily: 'Comic Sans MS', paddingLeft: '60px', textShadow: '0 0 2px black', }}>
                        Jakub B
                    </Typography>
                    <Typography style={{ fontSize: '105px', fontWeight: '600', letterSpacing: '0.1px', color: '#2F2F2F',
                                         fontFamily: 'Comic Sans MS', textShadow: '0 0 2px black', }}>e</Typography>
                    <Typography style={{ fontSize: '105px', fontWeight: '600', letterSpacing: '0.1px', color: '#FFFFFF',
                                        fontFamily: 'Comic Sans MS', textShadow: '0 0 4px black', transform: 'rotate(25deg)', 
                                        position: 'relative', bottom: '10%', left: '1%', }}>s</Typography>
                    <Typography style={{ fontSize: '105px', fontWeight: '600', letterSpacing: '0.1px', color: '#FFFFFF',
                                         fontFamily: 'Comic Sans MS', textShadow: '0 0 4px green', transform: 'rotate(-15deg)', 
                                         position: 'relative', top: '8%', }}>t</Typography>
                    <Typography style={{ fontSize: '105px', fontWeight: '600', letterSpacing: '0.1px', color: '#FFFFFF',
                                         fontFamily: 'Comic Sans MS', textShadow: '0 0 4px green', transform: 'rotate(25deg)',
                                         position: 'relative', top: '1%', left: '1%', }}>e</Typography>
                    <Typography style={{ fontSize: '105px', fontWeight: '600', letterSpacing: '0.1px', color: '#FFFFFF',
                                         fontFamily: 'Comic Sans MS', textShadow: '0 0 4px green', transform: 'rotate(25deg)', 
                                         position: 'relative', left: '1.5%', }}>r</Typography>
                </Box>
                <Link href='#About'>
                    <Box style={{
                        width: '30px', height: '50px', position: 'absolute', left: '50%', top: '72vh',
                        background: '#FFFFFF', border: '3px solid #2F2F2F', borderRadius: '15px', transform: 'translate(-50%, -50%)',
                        cursor: 'pointer',
                    }}>
                        <Box sx={{
                            width: '6px', height: '6px', position: 'absolute', top: '20%', left: '50%',
                            transform: 'translate(-50%, -50%) rotate(45deg)', animation: `${arrow} 1s ease-in-out infinite`,
                            border: '3px solid #2F2F2F', borderTop: 'transparent', borderLeft: 'transparent',
                        }}/>
                    </Box>
                </Link>
            </Box>
        </React.Fragment>
    );
}
