import * as React from 'react'

import { Box, Typography } from '@mui/material'

const style = {
    header: { fontWeight: '600', textAlign: 'center', },
    box: { border: '2px solid #2F2F2F', borderRadius: '10px', margin: '7px', padding: '5px', },
    text: { textAlign: 'center', fontWeight: '600', fontSize: '17.5px', },
    legend: { display: "flex", alignItems: "center", padding: "0 15px 0 15px", fontWeight: "600", },
    sampleBox: { height: "50px", width: "50px", },
}

const sx = {
    expert: { background: '#069C56', color: '#FFFFFF', fontWeight: '600', borderRadius: '10px', padding: '10px', display: 'inline-block', textAlign: 'center',
              margin: '5px', ':hover': { color: '#FFFB46', }, },
    proficient: { background: '#FF980E', color: '#FFFFFF', fontWeight: '600', borderRadius: '10px', padding: '10px', display: 'inline-block',
                  textAlign: 'center', margin: '5px', ':hover': { color: '#FFFB46', }, },
    familiar: { background: '#FF681E', color: '#FFFFFF', fontWeight: '600', borderRadius: '10px', padding: '10px', display: 'inline-block', textAlign: 'center',
                margin: '5px', ':hover': { color: '#FFFB46', }, },
}

export default function Tools() {
    return (
        <React.Fragment>
            <Box id="Tools" sx={{
                background: '#FFFFFF', scrollMarginTop: "7.4vh", boxShadow: 'inset 0px 1px 4px 0px rgba(0, 0, 0, 0.1)', borderBottom: '1px solid #DDDDDD',
            }}>
                <Box style={{ marginLeft: '15%', marginRight: '15%', paddingBottom: '2%', display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
                    <Typography style={ style.header } color='#92DCE5' fontSize='22.5px' paddingTop='3%'> Tools </Typography>
                    <Typography style={ style.header } color='#000000' fontSize='32.5px'> Some of my skills. </Typography>
                    <Typography style={ style.header } color='#2F2F2F' fontSize='17.5px' paddingBottom='2%' width="35%">
                        These are my skills including programming languages, finance, and languages.
                    </Typography>
                    <Box style={ style.box } width="100%">
                        <Typography style={ style.text }> Skills </Typography>
                        <Box style={{ display: 'flex', flexWrap: 'wrap', }}>
                            <Box style={ style.box } height="600px" width="400px">
                                <Typography style={ style.text }> Programming </Typography>
                                <Box style={ style.box }>
                                    <Typography style={ style.text }> Python </Typography>
                                    <Typography sx={ sx.proficient }> BeautifulSoup </Typography>
                                    <Typography sx={ sx.expert }> Numpy </Typography>
                                    <Typography sx={ sx.familiar }> TorchQuantum </Typography>
                                    <Typography sx={ sx.expert }> Pandas </Typography>
                                    <Typography sx={ sx.proficient }> Selenium </Typography>    
                                    <Typography sx={ sx.expert }> Numba </Typography>    
                                    <Typography sx={ sx.expert }> SciPy </Typography>
                                    <Typography sx={ sx.proficient }> PySpark </Typography>
                                    <Typography sx={ sx.proficient }> TensorFlow </Typography>
                                </Box>
                                <Box style={ style.box }>
                                  <Typography style={ style.text }> Web Development </Typography>
                                  <Typography sx={ sx.proficient }> HTML/CSS </Typography>
                                  <Typography sx={ sx.familiar }> React </Typography>
                                  <Typography sx={ sx.proficient }> JavaScript </Typography>
                                  <Typography sx={ sx.familiar }> Node.js </Typography>
                                </Box>
                                <Box style={ style.box } height="110px" width="200px">
                                  <Typography style={ style.text }> High Performance Computing </Typography>
                                  <Typography sx={ sx.proficient }> OpenMP </Typography>
                                  <Typography sx={ sx.proficient }> OpenMPI </Typography>
                                </Box>
                                <Typography sx={ sx.familiar }> Solidity </Typography>
                                <Typography sx={ sx.proficient }> SQL </Typography>
                                <Typography sx={ sx.proficient }> C++ </Typography>
                                <Typography sx={ sx.familiar }> Java </Typography>
                                <Typography sx={ sx.proficient }> C </Typography>
                                <Typography sx={ sx.proficient }> Rust </Typography>
                            </Box>
                            <Box style={ style.box } height="300px" width="200px">
                              <Typography style={ style.text }> Finance </Typography>
                              <Typography sx={ sx.proficient }> Cash Flow Statement </Typography>
                              <Typography sx={ sx.familiar }> Discounted Cash Flow (DCF) Modeling </Typography>
                              <Typography sx={ sx.proficient }> Income Statement </Typography>
                              <Typography sx={ sx.proficient }> Balance Sheet </Typography>
                            </Box>
                            <Box style={ style.box } height="200px" width="200px">
                              <Typography style={ style.text }> Languages </Typography>
                              <Typography sx={ sx.expert }> Polish </Typography>
                              <Typography sx={ sx.expert }> English </Typography>
                              <Typography sx={ sx.proficient }> Spanish (CT State Seal of Biliteracy) </Typography>
                            </Box>
                            <Box style={ style.box } height="475px" width="350px">
                                <Typography style={ style.text }> General </Typography>
                                <Box style={ style.box } height="140px" width="150px">
                                  <Typography style={ style.text }> Office </Typography>
                                  <Typography sx={ sx.proficient }> Power BI </Typography>
                                  <Typography sx={ sx.expert }> Microsoft Suite </Typography>
                                </Box>
                                <Box style={ style.box } height="135px" width="250px">
                                  <Typography style={ style.text }> Data Lakes / Computation </Typography>
                                  <Typography sx={ sx.proficient }> Snowflake </Typography>
                                  <Typography sx={ sx.proficient }> Databricks </Typography>
                                  <Typography sx={ sx.familiar }> Amazon Web Services </Typography>
                                </Box>
                                <Typography sx={ sx.familiar }> Linux Kernel Programming </Typography>
                                <Typography sx={ sx.proficient }> Management & Recruiting </Typography>
                                <Typography sx={ sx.familiar }> Qiskit </Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box display="flex" justifyContent="center" marginTop="1%">
                      <Box bgcolor="#069C56" style={ style.sampleBox }/>
                      <Typography style={ style.legend }> Expert </Typography>
                      <Box bgcolor="#FF980E" style={ style.sampleBox }/>
                      <Typography style={ style.legend }> Proficient </Typography>
                      <Box bgcolor="#FF681E" style={ style.sampleBox }/>
                      <Typography style={ style.legend }> Familiar </Typography>
                    </Box>
                </Box>
            </Box>
        </React.Fragment>
    );
}
