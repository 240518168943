import * as React from 'react'

import { Box, Typography, } from '@mui/material'
import { HomeWork as HomeWorkIcon, } from '@mui/icons-material'
import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineOppositeContent, TimelineDot, } from '@mui/lab'

const style = {
  header: { fontWeight: '600', textAlign: 'center', },
  title: {fontWeight: '600', },
  textBox: { width: '325px', height: '85px', padding: '10px', },
}

export default function Resume() {
    return (
        <React.Fragment>
            <Box id="Resume" sx={{
              background: '#FFFFFF', scrollMarginTop: "7.4vh", boxShadow: 'inset 0 1px 4px 0 rgba(0, 0, 0, 0.1)', borderBottom: '1px solid #DDDDDD',
            }}>
                <Box style={{ marginLeft: '27%', marginRight: '27%', }}>
                    <Typography style={ style.header } color='#F06000' fontSize='22.5px' paddingTop='5%'> Resume </Typography>
                    <Typography style={ style.header } color='#000000' fontSize='32.5px'> More of my credentials. </Typography>
                    <Typography style={ style.header } color='#2F2F2F' fontSize='17.5px' paddingBottom='1%'> Here are my work experiences and education. </Typography>
                    <Box>
                      <Typography style={ style.header } color='#F06000' fontSize='30px' paddingTop='4%'> Work Experience </Typography>
                      <Timeline position="alternate">
                          <TimelineItem>
                              <TimelineOppositeContent sx={{ m: 'auto 0' }} align="right" variant="body2" color="text.secondary"> May - Aug., 2023 </TimelineOppositeContent>
                              <TimelineSeparator> <TimelineConnector/> <TimelineDot color="primary" width="100px"> <HomeWorkIcon/> </TimelineDot> <TimelineConnector/> </TimelineSeparator>
                              <TimelineContent sx={{ py: '12px', px: 2, }}>
                                <Typography style={ style.title } variant="h6"> Arch Capital Group Ltd. </Typography>
                                <Typography variant="h7"> Information Technology Data Analyst Intern </Typography>
                                <Typography marginTop='7.5px' style={ style.textBox } variant="body2">
                                  Developed a PySpark-based mass auditing tool on Databricks at Arch Reinsurance for automating report verification, while also
                                  enhancing data security through Snowflake's access policies and improving operational efficiency via advanced data science
                                  and Power BI integration.
                                </Typography>
                              </TimelineContent>
                          </TimelineItem>
                          <TimelineItem>
                              <TimelineOppositeContent sx={{ m: 'auto 0' }} variant="body2" color="text.secondary"> Mar. - Apr., May - Present, 2023 </TimelineOppositeContent>
                              <TimelineSeparator> <TimelineConnector/> <TimelineDot color="secondary"> <HomeWorkIcon/> </TimelineDot> <TimelineConnector/> </TimelineSeparator>
                              <TimelineContent sx={{ py: '12px', px: 2 }}>
                                <Typography style={ style.title } variant="h6"> 3quilibrium Co. DBA Octavate </Typography>
                                <Typography variant="h7"> Software Engineer (Manager) </Typography>
                                <Typography marginTop='7.5px' style={ style.textBox } variant="body2">
                                  Supervised a team of over 10 developers, leading successful projects in web scraping, machine learning, and web development.
                                  Achievements include building a large-scale system for quantifying artist potential, integrating cloud infrastructure,
                                  refining image generation models, implementing sentiment analysis, and creating AI-powered web scraping tools.
                                </Typography>
                              </TimelineContent>
                          </TimelineItem>
                          <TimelineItem>
                              <TimelineOppositeContent sx={{ m: 'auto 0' }} align="right" variant="body2" color="text.secondary"> May - Aug., 2022 </TimelineOppositeContent>
                              <TimelineSeparator> <TimelineConnector/> <TimelineDot color="primary" width="100px"> <HomeWorkIcon/> </TimelineDot> <TimelineConnector/> </TimelineSeparator>
                              <TimelineContent sx={{ py: '12px', px: 2 }}>
                                <Typography style={ style.title } variant="h6"> SESCO Enterprises LLC </Typography>
                                <Typography variant="h7"> Quantitative Analyst (Energy Trading) </Typography>
                                <Typography marginTop='7.5px' style={ style.textBox } variant="body2">
                                  Created an automated trading strategy in the MISO electricity market, significantly improved power transfer distribution
                                  speed, refined energy exposure tools, and developed various forecasting and real-time energy monitoring tools for fundamental
                                  traders.
                                </Typography>
                              </TimelineContent>
                          </TimelineItem>
                        </Timeline>
                    </Box>
                    <Box paddingBottom='2%'>
                      <Typography style={ style.header } color='#F06000' fontSize='30px' paddingTop='2%'> Educational Background </Typography>
                      <Timeline position="alternate">
                        <TimelineItem>
                              <TimelineOppositeContent sx={{ m: 'auto 0' }} variant="body2" color="text.secondary"> 2021 - 2025 </TimelineOppositeContent>
                              <TimelineSeparator> <TimelineConnector/> <TimelineDot color="secondary"> <HomeWorkIcon/> </TimelineDot> <TimelineConnector/> </TimelineSeparator>
                              <TimelineContent sx={{ py: '12px', px: 2 }}>
                                <Typography style={ style.title } variant="h6"> Yale University </Typography>
                                <Typography variant="h7"> New Haven, CT </Typography>
                                <Typography marginTop='7.5px' style={ style.textBox } variant="body2">
                                  I am a Yale University student pursuing a dual major in Computer Science and Mathematics (B.S.), with a minor in Statistics &
                                  Data Science. My coursework covers a range of topics including data structures, quantum computing, deep learning, algorithms,
                                  and more.
                                </Typography>
                              </TimelineContent>
                          </TimelineItem>
                          <TimelineItem>
                              <TimelineOppositeContent sx={{ m: 'auto 0' }} align="right" variant="body2" color="text.secondary"> 2017 - 2021 </TimelineOppositeContent>
                              <TimelineSeparator> <TimelineConnector/> <TimelineDot color="primary" width="100px"> <HomeWorkIcon/> </TimelineDot> <TimelineConnector/> </TimelineSeparator>
                              <TimelineContent sx={{ py: '12px', px: 2 }}>
                                <Typography style={ style.title } variant="h6"> Academy of IT & Engineering </Typography>
                                <Typography variant="h7"> Stamford, CT </Typography>
                                <Typography marginTop='7.5px' style={ style.textBox } variant="body2">     
                                  I finished my high school diploma at the Academy of Information Technology and Engineering.
                                </Typography>
                              </TimelineContent>
                          </TimelineItem>
                        </Timeline>
                    </Box>
                </Box>
            </Box>
        </React.Fragment>
    );
}
