import * as React from 'react'

import { Box, Button, Typography } from '@mui/material'

const style = {
  image: { objectFit: 'cover', objectPosition: '0 -20px', border: '1px solid #92DCE5', width: "250px", height: "250px", borderRadius: "50%", marginRight: '40px', },
  header: { display: 'flex', justifyContent: 'left', alignItems: 'center', color: '#FFFFFF', fontWeight: '600', fontSize: '25px', marginBottom: '2%', },
  text: { display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#B5B5B5', fontWeight: '600', },
}

const sx = {
  button: { width: '200px', height: '50px', background: '#11ABB0', ':hover': { background: '#838C95', }, margin: '0 2% 0 2%', fontWeight: '600', },
  email: { color: '#92DCE5', float: 'left', fontWeight: '600', ':hover': { color: '#838C95', }, },
}

export default function About() {
    return (
        <React.Fragment>
            <Box id='About' sx={{
              background: '#181414', display: 'flex', justifyContent: 'top', alignContent: 'center', flexDirection: 'column',
              boxShadow: 'inset 0px 1px 4px 0px rgba(0, 0, 0, 0.1)', zIndex: 0, scrollMarginTop: '7.4vh', borderBottom: '1px solid #DDDDDD',
            }}>
                <Box style={{ margin: '2% 27% 2% 27%', }}>
                    <Typography style={{ color: '#92DCE5', fontSize: '20px', fontWeight: '900', textAlign: 'center', marginTop: '2%', letterSpacing: '5px', }}> About </Typography>
                    <Typography style={{ color: '#FFFFFF', fontSize: '40px', fontWeight: '700', textAlign: 'center', }}> Let me introduce myself. </Typography>
                    <Box style={{ display: 'flex', marginTop: '4%', }}>
                      <Box>
                        <Box component="img" src="headshot.jpg" style={ style.image }/>
                      </Box>
                      <Typography style={ style.text }>
                          I am a highly motivated and passionate college junior seeking a challenging role in the technology and finance industry. I am passionate about
                          programming, investment, and management, and I am eager to broaden my expertise and contribute to a dynamic and charismatic team.
                          <br /> <br />
                          I'm also a Yale University Class of 2025 student pursuing a double major in Computer Science and Mathematics. My primary interests
                          are artificial intelligence, machine learning, and quantitative finance.
                      </Typography>
                    </Box>
                    <Box style={{ display: 'flex', marginTop: '4%', }}>
                      <Box style={{ display: 'flex', flexDirection: 'column', marginRight: '2.5%', width: '50%', }}>
                        <Typography style={ style.header }>Profile</Typography>
                        <Typography style={style.text}>
                          I am currently in my junior year of school, and I would be open to any software engineering / quantitative finance opportunities. If
                          you think that I'm a good fit, please contact me via email.
                        </Typography>
                        <Typography style={style.header} marginTop='4%'> Email </Typography>
                        <Typography sx={sx.email}> jakubbester@yahoo.com </Typography>
                        <Typography sx={sx.email}> jakub.bester@yale.edu </Typography>
                      </Box>
                      <Box style={{ display: 'flex', flexDirection: 'column', marginLeft: '2.5%', width: '50%', }}>
                        <Typography style={ style.header }>Skills</Typography>
                        <Typography style={ style.text }>
                            I'm a cell-like independent teamworker, strong in research and coding. My areas of expertise are computer science, quantitative finance,
                            machine learning, and artificial intelligence.
                        </Typography>
                      </Box>
                    </Box>
                    <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '4%', }}>
                      <Button variant='contained' href="mailto:jakubbester@yahoo.com" sx={ sx.button }> Contact </Button>
                      <Button variant='contained' href="jakub_bester_resume.pdf" sx={ sx.button }> Download Resume </Button>
                    </Box>
                </Box>
            </Box>
        </React.Fragment>
    );
}
