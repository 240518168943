import * as React from 'react'

import { Box, Typography, Link } from '@mui/material'

const style = {
  header: { fontWeight: '600', textAlign: 'center', },
}

const sx = {
  box1: { border: '1px solid gray', width: '250px', height: '250px', ':hover': { background: 'black', }, objectFit: 'cover', },
  box2: { display: 'flex', flexDirection: 'column', width: '217px', height: '217px', opacity: '0', ':hover': { background: 'black', opacity: "0.75", }, position: 'absolute', left: 0,
          top: 0, color: 'black', padding: '17.5px', },
  box3: { margin: '10px', position: 'relative' },
  text1: { color: 'white', fontWeight: '600', },
  text2: { color: 'white', },
  text3: { color: 'white', fontSize: '50px', },
  link: { display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', textDecoration: 'none', },
}

export default function Projects() {
    return (
        <React.Fragment>
            <Box id="Projects" sx={{
                background: '#FAFAFA', scrollMarginTop: "7.4vh", boxShadow: 'inset 0px 1px 4px 0px rgba(0, 0, 0, 0.1)', borderBottom: '1px solid #DDDDDD',
            }}>
                <Box style={{ marginLeft: '20%', marginRight: '20%', paddingTop: '2%', }}>
                    <Typography style={ style.header } color='#F06000' fontSize='22.5px' paddingTop='3%'> Projects </Typography>
                    <Typography style={ style.header } color='#000000' fontSize='32.5px'> Check out my creations. </Typography>
                    <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap', margin: '3% 0 6% 0', }}>
                      <Box sx={ sx.box3 }>
                        <Box>
                          <Box sx={ sx.box1 } style={{ objectPosition: '-17px 0' }} component='img' src="project1.png"/>
                        </Box>
                        <Box sx={ sx.box2 }>
                          <Typography style={ sx.text1 }> Offloaded Deep Neural Network Interface </Typography>
                          <Typography style={ sx.text2 } variant="body2">
                            Implemented a custom Linux kernel module to interface with offloaded remote server running a deep neural network.
                          </Typography>
                          <Link target="https://github.com/jakubbester/offloaded-deep-neural-network-interface"
                                href="https://github.com/jakubbester/offloaded-deep-neural-network-interface" style={ sx.link }>
                            <Typography style={ sx.text3 }> + </Typography>
                          </Link>
                        </Box>
                      </Box>
                      {/* <Box sx={ sx.box3 }>
                        <Box sx={ sx.box1 } style={{ objectPosition: '-30px 0' }} component='img' src="project2.png"/>
                        <Box sx={ sx.box2 }>
                          <Typography style={ sx.text1 }> Finite Difference Approximation of Laplace Equation </Typography>
                          <Typography style={ sx.text2 } variant="body2">
                            Built a low-level and scalable C code to build a parallelized version of a solution to the Laplace Equation.
                          </Typography>
                          <Link target="https://github.com/jakubbester/finite-difference-approximation-of-laplace-equation"
                                href="https://github.com/jakubbester/finite-difference-approximation-of-laplace-equation" style={ sx.link }>
                            <Typography style={ sx.text3 }> + </Typography>
                          </Link>
                        </Box>
                      </Box> */}
                      <Box sx={ sx.box3 }>
                        <Box sx={ sx.box1 } style={{ objectPosition: '-20px 0' }} component='img' src="project3.png"/>
                        <Box sx={ sx.box2 }>
                          <Typography style={ sx.text1 }> LaTeX Macro Expander </Typography>
                          <Typography style={ sx.text2 } variant="body2">
                            Developed lightweight macro expander similar to LaTeX used by scientists and mathematicians.
                          </Typography>
                          <Link target="https://github.com/jakubbester/latex-macro-expander"
                                href="https://github.com/jakubbester/latex-macro-expander" style={ sx.link }>
                            <Typography style={ sx.text3 }> + </Typography>
                          </Link>
                        </Box>
                      </Box>
                      <Box sx={ sx.box3 }>
                        <Box sx={ sx.box1 } style={{ objectPosition: '0 0' }} component='img' src="project4.png"/>
                        <Box sx={ sx.box2 }>
                          <Typography style={ sx.text1 }> Explored QCNNs for Classification of Simulated Majorana Nodes </Typography>
                          <Typography style={ sx.text2 } variant="body2">
                            Used TensorFlow and torchquantum to implement and evaluate multiple QCNN models.
                          </Typography>
                          <Link target="https://github.com/jakubbester/quantum-cnn-state-characterization"
                                href="https://github.com/jakubbester/quantum-cnn-state-characterization" style={ sx.link }>
                            <Typography style={ sx.text3 }> + </Typography>
                          </Link>
                        </Box>
                      </Box>
                      <Box sx={ sx.box3 }>
                        <Box sx={ sx.box1 } style={{ objectPosition: '-170px 0' }} component='img' src="project5.png"/>
                        <Box sx={ sx.box2 }>
                          <Typography style={ sx.text1 }> Computer Graphics Final Project </Typography>
                          <Typography style={ sx.text2 } variant="body2">
                            Implemented a particle system in a 3D Three.js video game for our Computer Graphics Final Project using Smoothed Particle
                            Hydrodynamics.
                          </Typography>
                          <Link target="https://github.com/jakubbester/computer-graphics-final-project"
                                href="https://github.com/jakubbester/computer-graphics-final-project" style={ sx.link }>
                            <Typography style={ sx.text3 }> + </Typography>
                          </Link>
                        </Box>
                      </Box>
                      <Box sx={ sx.box3 }>
                        <Box sx={ sx.box1 } style={{ objectPosition: '-120px 0' }} component='img' src="project6.png"/>
                        <Box sx={ sx.box2 }>
                          <Typography style={ sx.text1 }> FISA Project </Typography>
                          <Typography style={ sx.text2 } variant="body2">
                            Modeled the definition of Electronic Surveillance as defined in "50 USC 1801 (f)" in Spass
                          </Typography>
                          <Link target="https://github.com/jakubbester/fisa-project" href="https://github.com/jakubbester/fisa-project" style={ sx.link }>
                            <Typography style={ sx.text3 }> + </Typography>
                          </Link>
                        </Box>
                      </Box>
                    </Box>
                </Box>
            </Box>
        </React.Fragment>
    );
}
