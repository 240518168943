import { useState, useEffect } from 'react'

import { Box } from '@mui/material'
import { keyframes } from '@mui/system'

const names = [ 'founder', 'creator', 'originator', 'builder' ]

const cursor = keyframes ` 50% { border-color: transparent; } `
const typing = keyframes ` from { width: 0; } `

export default function Title() {
  const [currentName, setCurrentName] = useState(names[0]);

  function setRandomName() {
    const index = Math.floor(Math.random() * names.length);
    let newName = names[index]
    if (newName === currentName)
      { setRandomName() }
    else
      { setCurrentName(newName) }
    return
  }

  useEffect(() => { setTimeout(() => { setRandomName() }, 5000); }, [currentName])

  return (
    <Box style={{ display: 'flex', flexDirection: 'row', }}>
      <Box sx={{ paddingRight: '15px', filter: 'blur(1px)', }}>
        &gt;
      </Box>
      <Box style={{ display: 'inline-block', }}>
        <Box sx={{ borderRight: '3px solid', width: '100%', whiteSpace: 'nowrap', overflow: 'hidden', paddingRight: '10%', filter: 'blur(1px)',
                  animation: `${typing} 5s steps(100) infinite, ${cursor} 0.7s step-end infinite alternate`, letterSpacing: '2px'}}>
          {currentName}
        </Box>
      </Box>
    </Box>
  )
}
