import * as React from 'react'
// import { useEffect, useState } from "react";

import { Toolbar, Typography, Link, Box } from '@mui/material'

const sx = {
    link: { fontWeight: '500', color: '#FFFFFF', ':hover': { color: '#FFFB46', }},
    // header1: { height: '7.5vh', background: '#181414', boxShadow: '0 -2px 10px 1px black', color: '#FFFFFF', position: 'sticky', top: 0, zIndex: 100, padding: 0, },
    // header2: { height: '7.5vh', background: '#FFFFFF', boxShadow: '0 -2px 10px 1px black', color: '#181414', position: 'sticky', top: 0, zIndex: 100, padding: 0, },
}

export default function Header() {
  // const [header, setHeader] = useState("header")

  // const listenScrollEvent = e => {
  //   if (window.scrollY < 73) {
  //     console.log(header)
  //     return setHeader({ height: '7.5vh', background: '#181414', boxShadow: '0 -2px 10px 1px black', color: '#FFFFFF', position: 'sticky', top: 0, zIndex: 100, padding: 0, })
  //   } else if (window.scrollY > 70) {
  //     console.log(header)
  //     return setHeader({ height: '7.5vh', background: '#FFFFFF', boxShadow: '0 -2px 10px 1px black', color: '#181414', position: 'sticky', top: 0, zIndex: 100, padding: 0, })
  //   } 
  // }
  
  // useEffect(() => {
  //   window.addEventListener('scroll', listenScrollEvent);
  //   return () => window.removeEventListener('scroll', listenScrollEvent);
  // }, []);

    return (
        <React.Fragment>
            <Box sx={{ height: '7.5vh', background: '#181414', boxShadow: '0 -2px 10px 1px black', color: '#FFFFFF', position: 'sticky', top: 0, zIndex: 100, padding: 0, }}>
                <Toolbar style={{ height: '100%', }}>
                    <Typography sx={{ fontWeight: '600', fontSize: '17.5px', letterSpacing: '3px', ':hover': { color: '#FFFB46', }}}>
                        &#43; &#215; &minus; &#247; &#61;
                    </Typography>
                    <Box display='flex' gap='25px' sx={{margin: 'auto'}}>
                        <Link href='#Home' underline='none' sx={ sx.link }> Home </Link>
                        <Link href='#About' underline='none' sx={ sx.link }> About </Link>
                        <Link href='#Resume' underline='none' sx={ sx.link }> Resume </Link>
                        <Link href='#Classes' underline='none' sx={ sx.link }> Classes </Link>
                        <Link href='#Tools' underline='none' sx={ sx.link }> Tools </Link>
                        <Link href='#Projects' underline='none' sx={ sx.link }> Projects </Link>
                        {/* <Link href='#Activities' underline='none' sx={ sx.link }> Activities </Link> */}
                        <Link href='#Awards' underline='none' sx={ sx.link }> Awards </Link>
                        {/* <Link href='#Statistics' underline='none' sx={ sx.link }> Statistics </Link> */}
                        {/* <Link href='#Contact' underline='none' sx={ sx.link }> Contact </Link> */}
                    </Box>
                    <Typography sx={{ fontWeight: '600', fontSize: '15px', letterSpacing: '1px', ':hover': { color: '#FFFB46', }}}> Jakub Bester </Typography>
                </Toolbar>
            </Box>
        </React.Fragment>
    );
}
