import * as React from 'react'

import { Box, Typography } from '@mui/material'

export default function Footer() {
    return (
        <React.Fragment>
            <Box sx={{ height: '13vh', background: '#181414', boxShadow: '0 2px 10px 1px black', display: 'flex',
                       flexDirection: 'row', justifyContent: 'center', alignItems: 'center', color: '#757575', }}>
                <Typography sx={{ fontWeight: '600', ':hover': { color: '#FFFB46', }}}>Made by Jakub Bester</Typography>
                {/* <Box style={{ background: '#FFFB46', width: "5px", height: "5px", borderRadius: '50%', marginLeft: '10px', marginRight: '10px', }}/>
                <Typography sx={{ fontWeight: '600', ':hover': { color: '#FFFB46', }}}>&copy; Copyright 2023 CeeVee</Typography> */}
                <Box style={{ background: '#FFFB46', width: "5px", height: "5px", borderRadius: '50%', marginLeft: '10px', marginRight: '10px', }}/>
                <Typography sx={{ fontWeight: '600', ':hover': { color: '#FFFB46', }}}>Design inspired by Sunwoo Kang & Adham Dannaway</Typography>
            </Box>
        </React.Fragment>
    );
}
