import * as React from 'react'

import { Box, Typography } from '@mui/material'

const sx = {
    box: { ':hover': { background: 'black', }},
}

const styles = {
    box1: { display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', },
    box2: { display: 'flex', flexWrap: 'wrap', flexDirection: 'column', height: '435px', width: '490px', borderRadius: '10px', border: '2px solid #181818', padding: '5px', 
            margin: '5px', },
    time1: { display: 'flex', alignItems: 'center' },
    time: { textAlign: 'center', padding: '10px', fontWeight: '600', borderRadius: '10px', },
    box: { display: 'flex', alignItems: 'center', width: '200px', height: '115px', borderRadius: '10px', padding: '10px', background: '#383838', margin: '5px', },
    number: { color: '#FFFFFF', fontSize: '17.5px', fontWeight: '600', textAlign: 'center', margin: '10px', },
    title: { background: 'white', borderRadius: '10px', padding: '10px', color: '#2F2F2F', fontSize: '65%', fontWeight: '600', textAlign: 'center', margin: '10px', },
    text: { color: '#FFFFFF', fontSize: '10px', textAlign: 'center', },
}

export default function Classes() {
    return (
        <React.Fragment>
            <Box id="Classes" sx={{
              background: '#FAFAFA', scrollMarginTop: "7.4vh", boxShadow: 'inset 0 1px 4px 0 rgba(0, 0, 0, 0.1)', borderBottom: '1px solid #DDDDDD',
            }}>
                <Box style={{ marginLeft: '27%', marginRight: '27%', paddingTop: '2%', }}>
                    <Typography sx={{ color: '#92DCE5', fontSize: '20px', fontWeight: '900', textAlign: 'center', letterSpacing: '5px', }}> Classes </Typography>
                    <Typography sx={{ color: '#181414', fontSize: '35px', fontWeight: '700', textAlign: 'center', }}> More of my coursework. </Typography>
                    <Typography sx={{ color: '#181414', fontSize: '15px', fontWeight: '700', textAlign: 'center', }}>
                      Here are the courses that I took during my time at Yale.
                    </Typography>
                </Box>
                <Box style={{ display: 'flex', background: '#F2F2F2', margin: '2% 7.5% 2% 7.5%', overflowX: 'scroll', padding: '20px', borderRadius: '20px', }}>
                    <Box style={ styles.box1 }>
                        <Box style={ styles.time1 }>
                            <Typography style={ styles.time }> Fall 2021 </Typography>
                        </Box>
                        <Box style={ styles.box2 }>
                            <Box style={ styles.box } sx={ sx.box }>
                                <Typography style={ styles.number }> CPSC 223 </Typography>
                                <Typography style={ styles.title } variant="body2"> Introduction to Data Structures and Algorithms </Typography>
                                {/* <Typography style={ styles.text }>
                                    Topics include programming in C; data structures (arrays, stacks, queues, lists, trees, heaps, graphs); sorting and searching;
                                    storage allocation and management; data abstraction; programming style; testing and debugging; writing efficient programs.
                                </Typography> */}
                            </Box>
                            <Box style={ styles.box } sx={ sx.box }>
                                <Typography style={ styles.number }> MATH 225 </Typography>
                                <Typography style={ styles.title }> Proof Based Linear Algebra </Typography>
                                {/* <Typography style={ styles.text }>
                                    An introduction to the theory of vector spaces, matrix theory and linear transformations, determinants, eigenvalues, inner
                                    productspaces, spectral theorem. The course focuses on conceptual understanding and serves as an introduction to writing
                                    mathematical proofs. For an approach focused on applications rather than proofs, consider MATH 222. Students with a strong
                                    mathematical background or interest are encouraged to consider MATH 226.
                                </Typography> */}
                            </Box>
                            <Box style={ styles.box } sx={ sx.box }>
                                <Typography style={ styles.number }> PLSH 150 </Typography>
                                <Typography style={ styles.title }> Advanced Polish </Typography>
                                {/* <Typography style={ styles.text }>
                                    Improvement of high-level language skills through reading, comprehension, discussion, and writing. Focus on the study of
                                    language through major literary and cultural texts, as well as through film and other media. Exploration of major historical
                                    and cultural themes.
                                </Typography> */}
                            </Box>
                            <Box style={ styles.box } sx={ sx.box }>
                                <Typography style={ styles.number }> S&DS 240 </Typography>
                                <Typography style={ styles.title }> An Introduction to Probability Theory </Typography>
                                {/* <Typography style={ styles.text }>
                                    Introduction to probability theory. Topics include probability spaces, random variables, expectations and probabilities,
                                    conditional probability, independence, discrete and continuous distributions, central limit theorem, Markov chains, and
                                    probabilistic modeling. This course counts towards the Data Science certificate but not the Statistics and Data Science major.
                                </Typography> */}
                            </Box>
                            <Box style={ styles.box } sx={ sx.box }>
                                <Typography style={ styles.number }> ENGL 114 </Typography>
                                <Typography style={ styles.title }> Writing Seminars: The Once and Future Campus </Typography>
                                {/* <Typography style={ styles.text }>
                                    Instruction in writing well-reasoned analyses and academic arguments, with emphasis on the importance of reading, research,
                                    and revision. Using examples of nonfiction prose from a variety of academic disciplines, individual sections focus on
                                    topics such as the city, childhood, globalization, inequality, food culture, sports, and war.
                                </Typography> */}
                            </Box>
                        </Box>
                    </Box>
                    <Box style={ styles.box1 }>
                        <Box style={ styles.time1 }>
                            <Typography style={ styles.time }> Spring 2021 </Typography>
                        </Box>
                        <Box style={ styles.box2 }>
                            <Box style={ styles.box } sx={ sx.box }>
                                <Typography style={ styles.number }> CPSC 323 </Typography>
                                <Typography style={ styles.title }> Introduction to Sytems Programming and Computer Ogranization </Typography>
                                {/* <Typography style={ styles.text }>
                                    Topics include programming in C; data structures (arrays, stacks, queues, lists, trees, heaps, graphs); sorting and searching;
                                    storage allocation and management; data abstraction; programming style; testing and debugging; writing efficient programs.
                                </Typography> */}
                            </Box>
                            <Box style={ styles.box } sx={ sx.box }>
                                <Typography style={ styles.number }> CPSC 276 </Typography>
                                <Typography style={ styles.title }> Advanced Applications of Computer and Data Science for the Digital Humanities </Typography>
                                {/* <Typography style={ styles.text }>
                                    An introduction to the theory of vector spaces, matrix theory and linear transformations, determinants, eigenvalues, inner
                                    productspaces, spectral theorem. The course focuses on conceptual understanding and serves as an introduction to writing
                                    mathematical proofs. For an approach focused on applications rather than proofs, consider MATH 222. Students with a strong
                                    mathematical background or interest are encouraged to consider MATH 226.
                                </Typography> */}
                            </Box>
                            <Box style={ styles.box } sx={ sx.box }>
                                <Typography style={ styles.number }> CPSC 210 </Typography>
                                <Typography style={ styles.title }> Power, Security, and Surveillance: Political Challenges of the Computer Age </Typography>
                                {/* <Typography style={ styles.text }>
                                    Improvement of high-level language skills through reading, comprehension, discussion, and writing. Focus on the study of
                                    language through major literary and cultural texts, as well as through film and other media. Exploration of major historical
                                    and cultural themes.
                                </Typography> */}
                            </Box>
                            <Box style={ styles.box } sx={ sx.box }>
                                <Typography style={ styles.number }> MATH 255 </Typography>
                                <Typography style={ styles.title }> Real Analysis I </Typography>
                                {/* <Typography style={ styles.text }>
                                    Introduction to probability theory. Topics include probability spaces, random variables, expectations and probabilities,
                                    conditional probability, independence, discrete and continuous distributions, central limit theorem, Markov chains, and
                                    probabilistic modeling. This course counts towards the Data Science certificate but not the Statistics and Data Science major.
                                </Typography> */}
                            </Box>
                        </Box>
                    </Box>
                    <Box style={ styles.box1 }>
                        <Box style={ styles.time1 }>
                            <Typography style={ styles.time }> Fall 2022 </Typography>
                        </Box>
                        <Box style={ styles.box2}>
                            <Box style={ styles.box } sx={ sx.box }>
                                <Typography style={ styles.number }> CPSC 524 </Typography>
                                <Typography style={ styles.title }> Parallel Programming Techniques </Typography>
                                {/* <Typography style={ styles.text }>
                                    Topics include programming in C; data structures (arrays, stacks, queues, lists, trees, heaps, graphs); sorting and searching;
                                    storage allocation and management; data abstraction; programming style; testing and debugging; writing efficient programs.
                                </Typography> */}
                            </Box>
                            <Box style={ styles.box } sx={ sx.box }>
                                <Typography style={ styles.number }> CPSC 547 </Typography>
                                <Typography style={ styles.title }> Introduction to Quantum Computing </Typography>
                                {/* <Typography style={ styles.text }>
                                    An introduction to the theory of vector spaces, matrix theory and linear transformations, determinants, eigenvalues, inner
                                    productspaces, spectral theorem. The course focuses on conceptual understanding and serves as an introduction to writing
                                    mathematical proofs. For an approach focused on applications rather than proofs, consider MATH 222. Students with a strong
                                    mathematical background or interest are encouraged to consider MATH 226.
                                </Typography> */}
                            </Box>
                            <Box style={ styles.box } sx={ sx.box }>
                                <Typography style={ styles.number }> CPSC 575 </Typography>
                                <Typography style={ styles.title }> Computational Vision and Biological Perception </Typography>
                                {/* <Typography style={ styles.text }>
                                    Improvement of high-level language skills through reading, comprehension, discussion, and writing. Focus on the study of
                                    language through major literary and cultural texts, as well as through film and other media. Exploration of major historical
                                    and cultural themes.
                                </Typography> */}
                            </Box>
                            <Box style={ styles.box } sx={ sx.box }>
                                <Typography style={ styles.number }> CPSC 529 </Typography>
                                <Typography style={ styles.title }> Principles of Computer System Design </Typography>
                                {/* <Typography style={ styles.text }>
                                    Introduction to probability theory. Topics include probability spaces, random variables, expectations and probabilities,
                                    conditional probability, independence, discrete and continuous distributions, central limit theorem, Markov chains, and
                                    probabilistic modeling. This course counts towards the Data Science certificate but not the Statistics and Data Science major.
                                </Typography> */}
                            </Box>
                            <Box style={ styles.box } sx={ sx.box }>
                                <Typography style={ styles.number }> CPSC 515 </Typography>
                                <Typography style={ styles.title }> Law, Security, and Logic </Typography>
                                {/* <Typography style={ styles.text }>
                                    Introduction to probability theory. Topics include probability spaces, random variables, expectations and probabilities,
                                    conditional probability, independence, discrete and continuous distributions, central limit theorem, Markov chains, and
                                    probabilistic modeling. This course counts towards the Data Science certificate but not the Statistics and Data Science major.
                                </Typography> */}
                            </Box>
                        </Box>
                    </Box>
                    <Box style={ styles.box1 }>
                        <Box style={ styles.time1 }>
                            <Typography style={ styles.time }> Spring 2023 </Typography>
                        </Box>
                        <Box style={ styles.box2 }>
                            <Box style={ styles.box } sx={ sx.box }>
                                <Typography style={ styles.number }> AMTH 247 </Typography>
                                <Typography style={ styles.title }> Introduction to Partial Differential Equations </Typography>
                                {/* <Typography style={ styles.text }>
                                    Topics include programming in C; data structures (arrays, stacks, queues, lists, trees, heaps, graphs); sorting and searching;
                                    storage allocation and management; data abstraction; programming style; testing and debugging; writing efficient programs.
                                </Typography> */}
                            </Box>
                            <Box style={ styles.box } sx={ sx.box }>
                                <Typography style={ styles.number }> CPSC 365 </Typography>
                                <Typography style={ styles.title }> Algorithms </Typography>
                                {/* <Typography style={ styles.text }>
                                    An introduction to the theory of vector spaces, matrix theory and linear transformations, determinants, eigenvalues, inner
                                    productspaces, spectral theorem. The course focuses on conceptual understanding and serves as an introduction to writing
                                    mathematical proofs. For an approach focused on applications rather than proofs, consider MATH 222. Students with a strong
                                    mathematical background or interest are encouraged to consider MATH 226.
                                </Typography> */}
                            </Box>
                            <Box style={ styles.box } sx={ sx.box }>
                                <Typography style={ styles.number }> CPSC 452 </Typography>
                                <Typography style={ styles.title }> Deep Learning Theory and Applications </Typography>
                                {/* <Typography style={ styles.text }>
                                    Improvement of high-level language skills through reading, comprehension, discussion, and writing. Focus on the study of
                                    language through major literary and cultural texts, as well as through film and other media. Exploration of major historical
                                    and cultural themes.
                                </Typography> */}
                            </Box>
                            <Box style={ styles.box } sx={ sx.box }>
                                <Typography style={ styles.number }> CPSC 470 </Typography>
                                <Typography style={ styles.title }> Artificial Intelligence </Typography>
                                {/* <Typography style={ styles.text }>
                                    Introduction to probability theory. Topics include probability spaces, random variables, expectations and probabilities,
                                    conditional probability, independence, discrete and continuous distributions, central limit theorem, Markov chains, and
                                    probabilistic modeling. This course counts towards the Data Science certificate but not the Statistics and Data Science major.
                                </Typography> */}
                            </Box>
                            <Box style={ styles.box } sx={ sx.box }>
                                <Typography style={ styles.number }> CPSC 477 </Typography>
                                <Typography style={ styles.title }> Natural Language Processing </Typography>
                                {/* <Typography style={ styles.text }>
                                    Introduction to probability theory. Topics include probability spaces, random variables, expectations and probabilities,
                                    conditional probability, independence, discrete and continuous distributions, central limit theorem, Markov chains, and
                                    probabilistic modeling. This course counts towards the Data Science certificate but not the Statistics and Data Science major.
                                </Typography> */}
                            </Box>
                            <Box style={ styles.box } sx={ sx.box }>
                                <Typography style={ styles.number }> CPSC 478 </Typography>
                                <Typography style={ styles.title }> Computer Graphics </Typography>
                                {/* <Typography style={ styles.text }>
                                    Introduction to probability theory. Topics include probability spaces, random variables, expectations and probabilities,
                                    conditional probability, independence, discrete and continuous distributions, central limit theorem, Markov chains, and
                                    probabilistic modeling. This course counts towards the Data Science certificate but not the Statistics and Data Science major.
                                </Typography> */}
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </React.Fragment>
    );
}
