import * as React from 'react'

import About from '../../components/About'
// import Activities from '../../components/Activities'
// import Contact from '../../components/Contact'
import Footer from '../../components/Footer'
import Header from '../../components/Header'
import Home from '../../components/Home'
import Projects from '../../components/Projects'
import Resume from '../../components/Resume'
// import Statistics from '../../components/Statistics'
import Classes from '../../components/Classes'
import Tools from '../../components/Tools'
import Awards from '../../components/Awards'

export default function MainPage() {
    return (
        <React.Fragment>
            <Header/>
            <Home/>
            <About/>
            <Resume/>
            <Classes/>
            {/* <Activities/> */}
            {/* <Statistics/> */}
            <Tools/>
            <Projects/>
            <Awards/>
            {/* <Contact/> */}
            <Footer/>
        </React.Fragment>
    )
}
